<template>
  <b-toast
    id="danger-toast"
    variant="danger"
    auto-hide-delay="3000"
  >
    <template #toast-title>
      <div class="d-flex flex-grow-1 align-items-baseline">
        <strong class="mr-auto">Ошибка!</strong>
      </div>
    </template>
    {{ message }}
  </b-toast>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: mapGetters({ message: 'notifications/message' })
};
</script>

<style>
</style>
